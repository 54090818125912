<template>
  <div class="AddQuestionnaire">
    <div class="crumbs">
      <a-icon class="return" @click="_returnPage" type="left" />
      <!--<div style="margin: 0 auto">-->
      <!--<span @click="_skipPage(0)" :class="{'crumbs-item':'crumbs-item','ant-menu-item-selected':itemActive==0?true:''}">创建项目 <span v-show="itemActive===0" class="ant-btn-primary"></span></span>-->
      <!--<a-icon class="crumbs-icon" type="right"/>-->
      <!--<span @click="skipId?_skipPage(1):''" :class="{'crumbs-item':'crumbs-item','ant-menu-item-selected':itemActive==1?true:''}" :style="skipId?'':'color: #aaa'">发布项目 <span v-show="itemActive===1" class="ant-btn-primary"></span></span>-->
      <!--<a-icon class="crumbs-icon" type="right"/>-->
      <!--<span @click="skipId?_skipPage(2):''" :class="{'crumbs-item':'crumbs-item','ant-menu-item-selected':itemActive==2?true:''}" :style="skipId?'':'color: #aaa'">统计数据 <span v-show="itemActive===2" class="ant-btn-primary"></span></span>-->
      <!--</div>-->
      <div
        style="position: absolute;top:0; right: 25px;height:100%"
        v-if="!$route.query.preview"
      >
        <a-button
          v-if="skipId && !$route.query.isRed"
          type="primary"
          @click="_deriveData"
          style="margin-right:100px"
          >题目导出</a-button
        >
        <a-button type="primary" @click="_subMit">{{
          skipId && !$route.query.isRed ? '修改' : '发布并分享'
        }}</a-button>
      </div>
      <div v-else style="position: absolute;top:0; right: 25px;height:100%">
        <a-button type="primary" @click="_skipNewTmp">使用模板</a-button>
      </div>
    </div>
    <div class="container">
      <div class="content-left">
        <!--问卷-->
        <div v-if="type == 1">
          <div class="item flex">
            <span class="item-label">所属分类</span>
            <a-select
              v-if="classifyList.length != 0"
              v-model="questionnaireType"
              style="flex: 1"
              @change="_getType"
            >
              <a-select-option :value="-1">无</a-select-option>
              <a-select-option
                :value="index"
                :key="index"
                v-for="(item, index) in classifyList"
                >{{ item.title }}</a-select-option
              >
            </a-select>
          </div>

          <div class="item flex">
            <span class="item-label">是否共享到模板库</span>
            <a-switch size="small" v-model="isShare" />
          </div>

          <div class="item flex" style="justify-content: end">
            <span class="item-label">平台限制</span>
            <div style="flex: 1">
              <a-select v-model="IsLimitPlatform" style="flex: 1;width:100%">
                <a-select-option :value="0">不限</a-select-option>
                <a-select-option :value="1">微信</a-select-option>
                <a-select-option :value="2">企业微信</a-select-option>
              </a-select>
            </div>
          </div>

          <div class="line"></div>

          <div class="item">
            <span class="item-label"
              >创建人：{{
                skipId && !$route.query.isRed
                  ? qtInfo.CreateUserName
                  : __USER__.name
              }}</span
            >
          </div>

          <div class="item">
            <span class="item-label">试题数量：{{ infoData.length }}</span>
          </div>

          <div class="item" v-if="isSet">
            <span class="item-label">创建日期：{{ qtInfo.CreateTime }}</span>
          </div>

          <div class="line"></div>

          <div class="item">
            <span class="item-label">问卷说明</span>
            <div style="margin-top: 10px">
              <a-textarea
                placeholder="请输入"
                v-model="questionnaireDel"
                :rows="7"
              />
            </div>
          </div>

          <div class="item">
            <span class="item-label">问卷结尾说明</span>
            <div style="margin-top: 10px">
              <a-textarea
                placeholder="请输入"
                v-model="EndExplain"
                :rows="7"
              />
            </div>
          </div>

          <div
            class="item  flex"
            style="cursor: pointer"
            @click="advancedSet = !advancedSet"
          >
            <span class="item-label">
              <span>高级设置</span>
            </span>
            <a-icon type="right" style="color: #aaa" />
          </div>

          <div v-show="advancedSet">
            <div class="item flex" style="cursor: pointer">
              <span class="item-label">
                <span>标识</span>
              </span>
              <a-input
                style="width: 150px"
                placeholder="唯一标识"
                v-model="identification"
              />
            </div>

            <div class="item flex" style="cursor: pointer">
              <span class="item-label">
                <span>关联对象</span>
              </span>
              <span style="position: relative;overflow: hidden;">
                <person
                  class="person"
                  v-bind="personObj"
                  @getData="_relevanceObject"
                ></person>
                <a-icon
                  style="font-size: 20px;color: #aaa"
                  type="plus-circle"
                />
              </span>
            </div>
            <div class="item">
              <ul class="object-list flex">
                <li
                  v-for="(item, index) in relevanceObject"
                  @mouseover="relevanceObjectIndex = index"
                  @mouseout="relevanceObjectIndex = -1"
                >
                  <img class="object-img" :src="item.icon" alt="" />
                  <div class="object-name" v-text="item.title"></div>
                  <a-icon
                    v-show="relevanceObjectIndex == index"
                    type="close-circle"
                    class="remove-icon"
                    @click="_delRelevanceObject(index)"
                  />
                </li>
              </ul>
            </div>

            <div class="item flex" style="cursor: pointer">
              <span class="item-label">
                <span>可见范围</span>
              </span>
              <span style="position: relative;overflow: hidden;">
                <person
                  class="person"
                  v-bind="visibleScope"
                  @getData="_visibleScope"
                ></person>
                <a-icon
                  style="font-size: 20px;color: #aaa"
                  type="plus-circle"
                />
              </span>
            </div>

            <div class="item">
              <ul class="object-list flex">
                <li
                  v-for="(item, index) in visibleScopeList"
                  @mouseover="visibleScopeIndex = index"
                  @mouseout="visibleScopeIndex = -1"
                >
                  <img class="object-img" :src="item.icon" alt="" />
                  <div class="object-name" v-text="item.title"></div>
                  <a-icon
                    v-show="visibleScopeIndex == index"
                    type="close-circle"
                    class="remove-icon"
                    @click="_delvisibleScope(index)"
                  />
                </li>
              </ul>
            </div>

            <div v-if="child != 1 && skipId">
              <div class="item flex" style="cursor: pointer">
                <span class="item-label">
                  <span>子问卷</span>
                </span>
                <a-button
                  type="primary"
                  ghost
                  style="border-radius: 20px"
                  @click="_addChild"
                  >创建子问卷</a-button
                >
              </div>
              <div>
                <div class="item flex" v-for="(item, index) in childrenList">
                  <span class="item-label" v-text="item.Title"></span>
                  <div>
                    <i
                      class="iconfont icon-bianji"
                      style="margin-right: 10px;cursor: pointer;color: #aaa"
                      @click="_setChildren(item.Id, index)"
                    ></i>
                    <a-popconfirm
                      placement="top"
                      cancelText="取消"
                      okText="确定"
                      @confirm="_removeChild(index)"
                    >
                      <template slot="title">
                        <p>确定删除么？</p>
                      </template>
                      <i
                        class="iconfont icon-shanchu-"
                        style="cursor: pointer;color: #aaa"
                      ></i>
                    </a-popconfirm>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--试卷-->
        <div v-if="type == 2">
          <div class="item flex">
            <span class="item-label">考试分类</span>
            <a-select
              v-if="classifyList.length != 0"
              v-model="questionnaireType"
              style="flex: 1"
              @change="_getType"
            >
              <a-select-option :value="-1">无</a-select-option>
              <a-select-option
                :value="index"
                :key="index"
                v-for="(item, index) in classifyList"
                >{{ item.title }}</a-select-option
              >
            </a-select>
          </div>

          <div class="item flex">
            <span class="item-label">是否共享到模板库</span>
            <a-switch size="small" v-model="isShare" />
          </div>

          <!-- <div class="item flex">
                        <span class="item-label">是否作为题库</span>
                        <a-switch size="small" v-model="isQB"/>
                    </div> -->

          <div class="item flex">
            <span class="item-label">考试时间</span>
            <a-range-picker
              format="YYYY-MM-DD HH:mm:ss"
              show-time
              v-model="defaultTime"
              style="flex: 1;"
              @change="_createdDate"
              @ok="_onTimeOk"
            />
          </div>

          <!-- <div class="item flex" v-if="!skipId">
                        <span class="item-label">考试时间</span>
                        <a-range-picker style="flex: 1;" :placeholder="['开始日期','结束日期']" @change="_createdDate"/>
                    </div> -->

          <div class="item flex" style="justify-content: end">
            <span class="item-label">考试时长</span>
            <div style="flex">
              <a-radio-group v-model="IsLimitDuration">
                <a-radio :value="1">
                  <a-input
                    style="width:45px;padding: 0;text-align: center"
                    v-show="IsLimitDuration == 1"
                    v-model="Duration"
                    @keyup="Duration = Duration.replace(/^(0+)|[^\d]+/g, '')"
                  ></a-input>
                  分钟
                </a-radio>
                <a-radio :value="0">不限时长</a-radio>
              </a-radio-group>
            </div>
          </div>

          <div class="item flex" style="justify-content: end">
            <span class="item-label">答卷次数</span>
            <div style="flex: 1">
              <a-radio-group name="radioGroup" v-model="IsAnswerLimit">
                <a-radio :value="0">无限次</a-radio>
                <a-radio :value="1"
                  >有限次
                  <a-input
                    style="width:40px;margin-left:2px;padding:0;text-align: center"
                    v-show="IsAnswerLimit == 1"
                    v-model="AnswerCount"
                    @keyup="
                      AnswerCount = AnswerCount.replace(/^(0+)|[^\d]+/g, '')
                    "
                  ></a-input
                ></a-radio>
              </a-radio-group>
            </div>
          </div>

          <div class="item flex" style="justify-content: end">
            <span class="item-label">及格分数</span>
            <div>
              <a-input
                style="width:70px;"
                v-model="PassScore"
                @keyup="PassScore = PassScore.replace(/^(0+)|[^\d]+/g, '')"
              ></a-input>
            </div>
          </div>

          <div class="item flex" style="justify-content: end">
            <span class="item-label">平台限制</span>
            <div style="flex: 1">
              <a-select v-model="IsLimitPlatform" style="flex: 1;width:100%">
                <a-select-option :value="0">不限</a-select-option>
                <a-select-option :value="1">微信</a-select-option>
                <a-select-option :value="2">企业微信</a-select-option>
              </a-select>
            </div>
          </div>

          <div class="line"></div>

          <div class="item">
            <span class="item-label"
              >总分：{{ qtInfo.Score ? qtInfo.Score : 0 }} 分</span
            >
          </div>

          <div class="item">
            <span class="item-label"
              >创建人：{{
                skipId ? qtInfo.CreateUserName : __USER__.name
              }}</span
            >
          </div>

          <div class="item">
            <span class="item-label">试题数量：{{ infoData.length }}</span>
          </div>

          <div class="item" v-if="isSet">
            <span class="item-label">创建日期：{{ qtInfo.CreateTime }}</span>
          </div>

          <div class="line"></div>

          <div class="item">
            <span class="item-label">考试说明</span>
            <div style="margin-top: 10px">
              <a-textarea
                placeholder="请输入"
                v-model="questionnaireDel"
                :rows="7"
              />
            </div>
          </div>

          <div
            class="item  flex"
            style="cursor: pointer"
            @click="advancedSet = !advancedSet"
          >
            <span class="item-label">
              <span>高级设置</span>
            </span>
            <a-icon type="right" style="color: #aaa" />
          </div>

          <div v-show="advancedSet">
            <div class="item flex" style="cursor: pointer">
              <span class="item-label">
                <span>标识</span>
              </span>
              <a-input
                style="width: 150px"
                placeholder="唯一标识"
                v-model="identification"
              />
            </div>

            <!--<div class="item flex" style="cursor: pointer">-->
            <!--<span class="item-label">-->
            <!--<span>关联对象</span>-->
            <!--</span>-->
            <!--<span style="position: relative;overflow: hidden;">-->
            <!--<person class="person" v-bind="personObj" @getData="_relevanceObject"></person>-->
            <!--<a-icon style="font-size: 20px;color: #aaa" type="plus-circle"/>-->
            <!--</span>-->
            <!--</div>-->
            <!--<div class="item">-->
            <!--<ul class="object-list flex">-->
            <!--<li v-for="(item,index) in relevanceObject" @mouseover="relevanceObjectIndex=index" @mouseout="relevanceObjectIndex=-1">-->
            <!--<img class="object-img" :src="item.icon" alt="">-->
            <!--<div class="object-name" v-text="item.title"></div>-->
            <!--<a-icon v-show="relevanceObjectIndex==index" type="close-circle" class="remove-icon" @click="_delRelevanceObject(index)"/>-->
            <!--</li>-->
            <!--</ul>-->
            <!--</div>-->

            <div class="item flex" style="cursor: pointer">
              <span class="item-label">
                <span>可见范围</span>
              </span>
              <span style="position: relative;overflow: hidden;">
                <person
                  class="person"
                  v-bind="visibleScope"
                  @getData="_visibleScope"
                ></person>
                <a-icon
                  style="font-size: 20px;color: #aaa"
                  type="plus-circle"
                />
              </span>
            </div>

            <div class="item">
              <ul class="object-list flex">
                <li
                  v-for="(item, index) in visibleScopeList"
                  @mouseover="visibleScopeIndex = index"
                  @mouseout="visibleScopeIndex = -1"
                >
                  <img class="object-img" :src="item.icon" alt="" />
                  <div class="object-name" v-text="item.title"></div>
                  <a-icon
                    v-show="visibleScopeIndex == index"
                    type="close-circle"
                    class="remove-icon"
                    @click="_delvisibleScope(index)"
                  />
                </li>
              </ul>
            </div>

            <!--<div v-if="child!=1 && skipId">-->
            <!--<div class="item flex" style="cursor: pointer">-->
            <!--<span class="item-label">-->
            <!--<span>子问卷</span>-->
            <!--</span>-->
            <!--<a-button type="primary" ghost style="border-radius: 20px" @click="_addChild">创建子问卷</a-button>-->
            <!--</div>-->
            <!--<div>-->
            <!--<div class="item flex" v-for="(item,index) in childrenList">-->
            <!--<span class="item-label" v-text="item.Title"></span>-->
            <!--<div>-->
            <!--<i class="iconfont icon-bianji" style="margin-right: 10px;cursor: pointer;color: #aaa" @click="_setChildren(item.Id,index)"></i>-->
            <!--<a-popconfirm placement="top" cancelText="取消" okText="确定" @confirm="_removeChild(index)">-->
            <!--<template slot="title">-->
            <!--<p>确定删除么？</p>-->
            <!--</template>-->
            <!--<i class="iconfont icon-shanchu-" style="cursor: pointer;color: #aaa"></i>-->
            <!--</a-popconfirm>-->
            <!--</div>-->
            <!--</div>-->
            <!--</div>-->
            <!--</div>-->
          </div>
        </div>
      </div>
      <div class="content-right">
        <div class="container-header">
          <div class="questionnaire-title">
            <input
              type="text"
              v-model="titleVal"
              placeholder="点击输入问卷名称"
              :readonly="isReadonly"
              @click="_setTitle($event)"
              @blur="_setTitleBlur"
            />
          </div>
          <div class="questionnaire-des">
            <input
              type="text"
              v-model="titleDel"
              placeholder="请输入问卷/考卷的描述"
              :readonly="isReadonly2"
              @click="_setTitleDel($event)"
              @blur="_setTitleDelBlur"
            />
          </div>
        </div>

        <div
          class="item-header-cate flex"
          v-if="type == 2 && !$route.query.preview && $route.query.id"
        >
          <div class="screen-list-item flex">
            <div class="flex">
              <span class="screen-item-label">分值筛选</span>
              <a-select
                style="width: 200px"
                @change="handleChange"
                :options="scoreList"
                placeholder="请选择"
                allowClear
                v-model="SelectScore"
              >
              </a-select>
            </div>
          </div>
          <div class="screen-list-item">
            <a-button style="margin-right:20px" @click="_reset">重置</a-button>
            <a-button type="primary" @click="_searchData">确定</a-button>
          </div>
        </div>

        <div
          class="content-item"
          v-for="(item, index) in infoData"
          v-if="isScreen == false"
        >
          <div class="item-header flex" style="align-items: end">
            <div class="item-title">
              {{ index + 1 }}.{{ item.OptionTitle }}
              <span v-if="type == 2">({{ item.Score }}分)</span>
            </div>
            <div class="operation-btn" v-if="!$route.query.preview">
              <i class="iconfont icon-bianji" @click="_setData(index)"></i>
              <a-popconfirm
                placement="top"
                cancelText="取消"
                okText="确定"
                @confirm="_removeItem(index)"
              >
                <template slot="title">
                  <p>确定删除么？</p>
                </template>
                <i class="iconfont icon-shanchu-"></i>
              </a-popconfirm>
              <a-icon
                type="up-circle"
                @click="index != 0 ? _upItem(index) : ''"
                :style="index != 0 ? '' : 'color:#ddd'"
              />
              <a-icon
                type="down-circle"
                @click="index != infoData.length - 1 ? _downItem(index) : ''"
                :style="index != infoData.length - 1 ? '' : 'color: #ddd'"
              />
            </div>
          </div>
          <div v-if="item.Explain" class="Explain">说明：{{item.Explain}}</div>
          <div class="item-content">
            <!--单选题-->
            <a-radio-group
              v-if="item.Type === 1 || item.Type === 3"
              v-model="item.AnswerValue"
              :disabled="optionDisabled"
            >
              <div class="item-row" v-for="tag in item.Children">
                <a-radio :value="tag.OptionTitle"
                  >{{ tag.OptionTitle
                  }}<span v-if="tag.Value && tag.Value > 0"
                    >({{ tag.Value }}分)</span
                  ></a-radio
                >
              </div>
            </a-radio-group>

            <!--多选题-->
            <a-checkbox-group
              v-if="item.Type === 2"
              v-model="item.AnswerValueArr"
              :disabled="optionDisabled"
            >
              <div class="item-row" v-for="tag in item.Children">
                <a-checkbox :value="tag.OptionTitle">{{
                  tag.OptionTitle
                }}</a-checkbox>
              </div>
            </a-checkbox-group>

            <!--问答题-->
            <a-input
              :type="
                item.ConfigParam.Content.NB == 1
                  ? 'number'
                  : item.ConfigParam.Content.EM == 1
                  ? 'email'
                  : 'text'
              "
              v-if="item.Type === 4 && item.ConfigParam.Content.UN == 0"
              v-model="item.AnswerValue"
              placeholder="请输入"
            />
            <a-textarea
              :rows="4"
              v-if="item.Type == 4 && item.ConfigParam.Content.UN == 1"
            ></a-textarea>
          </div>
        </div>

        <!--筛选-->
        <div
          class="content-item"
          v-for="(item, index) in screenData"
          v-if="isScreen == true"
        >
          <div class="item-header flex" style="align-items: end">
            <div class="item-title">
              {{ index + 1 }}.{{ item.OptionTitle }}
              <span v-if="type == 2">({{ item.Score }}分)</span>
            </div>
            <div class="operation-btn" v-if="!$route.query.preview">
              <i class="iconfont icon-bianji" @click="_setData(index)"></i>
              <a-popconfirm
                placement="top"
                cancelText="取消"
                okText="确定"
                @confirm="_removeItem(index)"
              >
                <template slot="title">
                  <p>确定删除么？</p>
                </template>
                <i class="iconfont icon-shanchu-"></i>
              </a-popconfirm>
              <a-icon
                type="up-circle"
                @click="index != 0 ? _upItem(index) : ''"
                :style="index != 0 ? '' : 'color:#ddd'"
              />
              <a-icon
                type="down-circle"
                @click="index != screenData.length - 1 ? _downItem(index) : ''"
                :style="index != screenData.length - 1 ? '' : 'color: #ddd'"
              />
            </div>
          </div>
          <div class="item-content">
            <!--单选题-->
            <a-radio-group
              v-if="item.Type === 1 || item.Type === 3"
              v-model="item.AnswerValue"
              :disabled="optionDisabled"
            >
              <div class="item-row" v-for="tag in item.Children">
                <a-radio :value="tag.OptionTitle"
                  >{{ tag.OptionTitle
                  }}<span v-if="tag.Value && tag.Value > 0"
                    >({{ tag.Value }}分)</span
                  ></a-radio
                >
              </div>
            </a-radio-group>

            <!--多选题-->
            <a-checkbox-group
              v-if="item.Type === 2"
              v-model="item.AnswerValueArr"
              :disabled="optionDisabled"
            >
              <div class="item-row" v-for="tag in item.Children">
                <a-checkbox :value="tag.OptionTitle">{{
                  tag.OptionTitle
                }}</a-checkbox>
              </div>
            </a-checkbox-group>

            <!--问答题-->
            <a-input
              :type="
                item.ConfigParam.Content.NB == 1
                  ? 'number'
                  : item.ConfigParam.Content.EM == 1
                  ? 'email'
                  : 'text'
              "
              v-if="item.Type === 4 && item.ConfigParam.Content.UN == 0"
              v-model="item.AnswerValue"
              placeholder="请输入"
            />
            <a-textarea
              :rows="4"
              v-if="item.Type == 4 && item.ConfigParam.Content.UN == 1"
            ></a-textarea>
          </div>
        </div>

        <div class="content-footer" v-if="!$route.query.preview">
          <div class="footer-title">创建新的大题</div>
          <div class="footer-btn">
            <a-button type="primary" @click="_showVisible(1)">单选题</a-button>
            <a-button type="primary" @click="_showVisible(2)">多选题</a-button>
            <a-button type="primary" @click="_showVisible(3)">判断题</a-button>
            <a-button type="primary" @click="_showVisible(4)">问答题</a-button>
          </div>
        </div>
      </div>
    </div>
    <a-drawer
      title="题目设置"
      placement="right"
      :closable="false"
      width="900"
      @close="_hideCancel"
      :visible="visible"
    >
      <div class="alert-container flex1">
        <div class="alert-left">
          <div class="title flex" style="align-items: end">
            <textarea
              class="title-input"
              v-model="OptionTitle"
              placeholder="请填写题目..."
            ></textarea>
            <div class="grade" v-show="type == 2">
              <span>分数</span>
              <input
                v-model="Score"
                @keyup="Score = Score.replace(/^(0+)|[^\d]+/g, '')"
                class="grade-input"
              />
              <span>分</span>
            </div>
          </div>

          <div class="preview" v-if="titleType === 4 || addList.length != 0">
            <!--单选题-->
            <a-radio-group
              v-if="titleType === 1 || titleType === 3"
              @change="_addChangeVlue(1, $event)"
              v-model="AnswerValueInput"
            >
              <div class="item-row" v-for="(item, index) in addList">
                <a-radio :value="item.OptionTitle"></a-radio>
                <span
                  v-text="item.OptionTitle"
                  :style="
                    item.OptionInput == 1 && setInputBtn != index
                      ? ''
                      : 'width:350px'
                  "
                  style="display: inline-block;cursor: pointer"
                  v-if="setInputBtn != index"
                  @click="_setAddItem(index)"
                ></span>
                <!--备注框-->
                <a-input
                  @click="_setAddItem(index)"
                  v-if="item.OptionInput == 1 && setInputBtn != index"
                  style="width:120px;margin-left: 5px;"
                />
                <!--输入值-->
                <a-input
                  v-model="item.OptionTitle"
                  v-show="setInputBtn == index"
                  :disabled="titleType == 3"
                  style="width:350px;margin-right: 5px;"
                  @change="_inputChange(1, $event)"
                />
                <span v-show="setInputBtn === index && titleType != 3">
                  <!--<a-icon type="setting" class="search-btn" @click="_setAddItem(index)" />-->
                  <a-icon
                    type="minus-circle"
                    class="search-btn"
                    @click="_removeAddList(index)"
                  />
                </span>
              </div>
            </a-radio-group>

            <!--多选题-->
            <a-checkbox-group
              v-if="titleType === 2"
              @change="_addChangeVlue(2, $event)"
              v-model="AnswerValueInput"
            >
              <div class="item-row" v-for="(item, index) in addList">
                <a-checkbox
                  :value="item.OptionTitle"
                  style="margin:0"
                ></a-checkbox>
                <span
                  v-text="item.OptionTitle"
                  :style="
                    item.OptionInput == 1 && setInputBtn != index
                      ? ''
                      : 'width:350px'
                  "
                  style="display: inline-block;cursor: pointer"
                  v-if="setInputBtn != index"
                  @click.stop="_setAddItem(index)"
                ></span>
                <!--备注框-->
                <a-input
                  @click="_setAddItem(index)"
                  v-if="item.OptionInput == 1 && setInputBtn != index"
                  style="width:120px;margin-left: 5px;"
                />
                <!--输入值-->
                <a-input
                  v-model="item.OptionTitle"
                  v-show="setInputBtn == index"
                  style="width:350px;margin-right: 5px;margin-left:10px;"
                  @change="_inputChange(2, $event)"
                />
                <span v-show="setInputBtn === index">
                  <!--<a-icon type="setting" class="search-btn" @click="_setAddItem(index)" />-->
                  <a-icon
                    type="minus-circle"
                    class="search-btn"
                    @click="_removeAddList(index)"
                  />
                </span>
              </div>
            </a-checkbox-group>

            <!--问答题-->
            <a-textarea v-if="titleType === 4" placeholder="请输入" :rows="4" />
          </div>

          <div class="add-btn flex" v-if="titleType != 4 && titleType != 3">
            <div @click="_showAddItem">
              <a-icon class="ant-menu-item-selected" type="plus" />
              <span class="ant-menu-item-selected">添加单个选项</span>
            </div>
            <div @click="batchAlert = true">
              <a-icon class="ant-menu-item-selected" type="ordered-list" />
              <span class="ant-menu-item-selected">批量添加选项</span>
            </div>
            <div class="batch-alert" v-show="batchAlert">
              <div class="batch-alert-title">批量添加选项</div>
              <div class="batch-alert-des">
                每行代表一个选项，可以添加多个选项
              </div>
              <a-textarea
                :rows="4"
                style="resize: none;margin-top:15px"
                v-model="batchAdd"
              />
              <div style="margin-top: 20px;text-align: right">
                <a-button style="margin-right: 15px;" @click="_hideBatchAlert"
                  >取消</a-button
                >
                <a-button type="primary" @click="_saveBatch">确定</a-button>
              </div>
            </div>
          </div>

          <div class="paper" v-if="type == 2">
            <div class="paper-row flex" v-if="titleType != 4">
              <span class="paper-row-label">答案</span>
              <a-input
                style="width:400px"
                v-model="AnswerValue"
                disabled
              ></a-input>
            </div>
            <div class="paper-row flex" v-if="titleType == 4">
              <span class="paper-row-label">答案</span>
              <a-input
                style="width:400px"
                value="此题没有正确答案，需要人工判分"
                disabled
              ></a-input>
            </div>

            <div class="paper-row flex" style="align-items: end">
              <span class="paper-row-label">解析</span>
              <a-input
                type="textarea"
                v-model="AnalysisValue"
                style="width:400px;height: 80px;resize: none"
              ></a-input>
            </div>
          </div>

          <div class="line"></div>
        </div>
        <div class="alert-right">
          <div class="right-title">题目设置</div>
          <div class="right-item-row flex">
            <span>整题设置</span>
          </div>

          <div class="right-item-row flex">
            <span>此题必答</span>
            <a-switch v-model="isMust" size="small" />
          </div>

          <div class="right-item-row flex">
            <span>题型</span>
            <a-radio-group
              size="small"
              v-model="titleType"
              @change="_cutTitleType"
            >
              <a-radio-button :value="1">单选</a-radio-button>
              <a-radio-button :value="2">多选</a-radio-button>
            </a-radio-group>
          </div>

          <div class="right-item-row flex">
            <span>标签</span>
            <a-input v-model="Tag" size="small" style="width: 160px;" />
          </div>

          <div class="right-item-row flex">
            <span>说明</span>
            <a-input v-model="opExplain" size="small" style="width: 160px;" />
          </div>

          <div
            style="margin-top:25px;"
            v-if="setInputBtn >= 0 || titleType == 4"
          >
            <div class="right-title" v-if="titleType != 4">选项设置</div>
            <div v-if="titleType != 4">
              <div class="right-item-row flex">
                <span>在选项后增加填空框</span>
                <a-switch
                  @change="_OptionInput"
                  v-model="OptionInput"
                  size="small"
                />
              </div>

              <div class="right-item-row flex">
                <span>选项值</span>
                <a-input
                  v-model="Value"
                  @change="_OptionUn"
                  size="small"
                  @keyup="Value = Value.replace(/[^\d]/g, '')"
                  style="width: 80px;"
                />
              </div>
            </div>

            <div v-if="OptionInput || titleType == 4">
              <div class="right-item-row flex">
                <span>内容限制</span>
                <a-select
                  size="small"
                  v-model="Content"
                  @change="_OptionContent"
                  style="width: 80px"
                >
                  <a-select-option value="UN">不限</a-select-option>
                  <a-select-option value="NB">数字</a-select-option>
                  <a-select-option value="LR">字母</a-select-option>
                  <a-select-option value="CN">中文</a-select-option>
                  <a-select-option value="EM">邮箱</a-select-option>
                  <a-select-option value="MB">手机号</a-select-option>
                </a-select>
              </div>

              <div class="right-item-row flex">
                <span>字数限制</span>
                <a-input
                  v-model="UN"
                  @change="_OptionUn"
                  size="small"
                  @keyup="UN = UN.replace(/^(0+)|[^\d]+/g, '')"
                  style="width: 80px;"
                />
              </div>

              <div v-if="titleType != 4">
                <div class="right-item-row flex">
                  <span>填空框是否必填</span>
                  <a-switch
                    @change="_ChildOptionInput"
                    v-model="ChildOptionInput"
                    size="small"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="alert-footer">
        <a-button style="margin-right:25px" @click="_hideCancel">取消</a-button>
        <a-button type="primary" @click="_addInfoData">确定</a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import person from 'components/SelectPersonnel/SelectPersonnel' //选人
var user = JSON.parse(window.sessionStorage.getItem('userInfo'))
export default {
  name: 'AddQuestionnaire',
  components: {
    person
  },
  data () {
    return {
      itemActive: 0,
      visible: false,
      infoData: [],
      titleType: -1,
      addInput: false,
      addList: [],
      addActive: 0,
      setInputBtn: 0,
      batchAlert: false,
      batchAdd: '',
      type: 1,
      isSet: false,
      userInfo: '',
      classifyList: [],
      questionnaireType: -1,
      questionnaireDel: '',
      EndExplain: '',//问卷结尾说明
      isShare: false,
      isReadonly: true,
      isReadonly2: true,
      titleVal: '',
      titleDel: '',
      isAddInput: false,
      advancedSet: false, //高级设置
      OptionTitle: '',
      AnswerValue: '',
      AnswerValueArr: [],
      AnswerValueInput: [],
      AnalysisValue: '',
      Score: 0,
      isMust: false,
      Content: 'UN',
      UN: '',
      qtInfo: '',
      questionnaireList: '',
      createdDate: [],
      skipId: '',
      CloneItem: '',

      IsAnswerLimit: 0,
      IsLimitDuration: 0,
      Duration: 0,
      AnswerCount: 0,
      startDate: '',
      endDate: '',
      defaultTime: [],
      startEndDate: '',
      PassScore: '',
      totalScore: 0,
      OptionInput: false,
      ChildOptionInput: false,
      setIndex: -1,
      relevanceObjectIndex: -1,
      visibleScopeIndex: -1,
      personObj: {
        rank: 0, //职级 0为不选，1为选
        nums: 10000, //选的数量
        department: 1, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
        contacts: '', //常用联系人 不要常用联系人传空''
        selectArr: this.relevanceObject //要传入的数据
      },
      visibleScope: {
        rank: 0, //职级 0为不选，1为选
        nums: 10000, //选的数量
        department: 1, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
        contacts: '', //常用联系人 不要常用联系人传空''
        selectArr: this.visibleScopeList //要传入的数据
      },
      visibleScopeList: [],
      relevanceObject: [], //关联对象
      identification: '', //唯一标识
      childrenList: [], //子问卷
      Value: 0,
      Tag: '', //题目标签
      opExplain: '', //题目说明
      isQB: false, //是否作为题库
      scoreList: [], //分值列表
      SelectScore: '',
      isScreen: false,
      screenData: [],
      optionDisabled: false,
      IsLimitPlatform:0,//是否限制平台
    }
  },
  created () {
    this.optionDisabled = this.$route.query.type == 2 ? true : false
    this.get_option()
  },
  methods: {
    get_option () {
      let self = this
      let user = JSON.parse(window.sessionStorage.getItem('userInfo'))
      this.user = user
      this.skipId = this.$route.query.id
      this.child = this.$route.query.child //是否是子问卷
      this.type = this.$route.query.type
      this.childrenList = this.$store.state.app.childrenQuestionnaire
      this.$axios.get(1206, { label: 'Questionnaire' }, res => {
        if (res.data.code == 1) {
          this.classifyList = res.data.data
          if (this.child != 1 && this.skipId) {
            let urlCode = this.$route.query.isRed == 1 ? 5836136 : 5836103
            this.$axios.get(
              urlCode,
              {
                Qid:
                  this.child == 4
                    ? this.$route.query.childrenId
                    : this.$route.query.id,
                UserId: user.uid
              },
              res => {
                if (res.data.code == 1) {
                  this.qtInfo = res.data.qtInfo
                  this.questionnaireList = res.data.QuestionnaireList
                  //this.questionnaireType = this.qtInfo.Type==1?1:0;
                  this.isShare = this.qtInfo.IsShare == 1 ? true : false
                  this.isQB = this.qtInfo.isQB == 1 ? true : false
                  this.startDate = this.qtInfo.StartTime
                  this.endDate = this.qtInfo.EndTime
                  if (this.qtInfo.StartTime && this.qtInfo.EndTime) {
                    this.defaultTime = [
                      this.__moment__(this.qtInfo.StartTime),
                      this.__moment__(this.qtInfo.EndTime)
                    ]
                    console.log(this.defaultTime)
                  }
                  this.createdDate = [this.startDate, this.endDate]
                  this.IsLimitDuration = this.qtInfo.IsLimitDuration
                  this.Duration = this.qtInfo.Duration
                  this.AnswerCount = this.qtInfo.AnswerCount
                  this.IsAnswerLimit = this.qtInfo.IsAnswerLimit
                  this.PassScore = this.qtInfo.PassScore
                  this.questionnaireDel = this.qtInfo.Explain
                  this.EndExplain = this.qtInfo.EndExplain
                  this.titleVal = this.qtInfo.Title
                  this.titleDel = this.qtInfo.Explain //明天产品确认
                  this.IsLimitPlatform = this.qtInfo.IsLimitPlatform
                  this.infoData = this.questionnaireList
                  this.visibleScopeList = this.qtInfo.PermitUser
                  this.visibleScope.selectArr = this.qtInfo.PermitUser
                  this.relevanceObject = this.qtInfo.Relevance
                  this.personObj.selectArr = this.qtInfo.Relevance
                  this.identification = this.qtInfo.Label
                  if (this.child != 3 && this.child != 4) {
                    this.childrenList = this.qtInfo.ChildrenList
                    this.$store.commit('TOGGLE_CHILDREN_QUESTIONNAIRE', {
                      clear: 1,
                      list: this.childrenList
                    })
                  }
                  for (let i = 0; i < this.classifyList.length; i++) {
                    if (self.classifyList[i].Id == self.qtInfo.CategoryId) {
                      self.questionnaireType = i
                    }
                  }
                  this.scoreList = this.setScoreList(this.questionnaireList)
                } else {
                  this.$message.error(res.data.msg)
                }
              }
            )
          }
        }
      })
    },
    _skipNewTmp () {
      this.$router.push({
        path: '/addQuestionnaire',
        query: {
          type: this.$route.query.type,
          id: this.$route.query.id,
          isRed: 1
        }
      })
    },
    _addChangeVlue (type, e) {
      let self = this
      console.log(e)
      if (type == 1) {
        this.AnswerValue = e.target.value
        this.AnswerValueArr = [e.target.value]
        for (let j = 0; j < self.addList.length; j++) {
          if (e.target.value == self.addList[j].OptionTitle) {
            self.addList[j].IsAnswer = 1
          } else {
            self.addList[j].IsAnswer = 0
          }
        }
      } else if (type == 2) {
        this.AnswerValue = e.length != 0 ? e.join(',') : ''
        this.AnswerValueArr = e
        //先重置是否是答案在重新设置
        for (let k = 0; k < self.addList.length; k++) {
          self.addList[k].IsAnswer = 0
        }
        for (let i = 0; i < e.length; i++) {
          for (let j = 0; j < self.addList.length; j++) {
            if (e[i] == self.addList[j].OptionTitle) {
              self.addList[j].IsAnswer = 1
            }
          }
        }
        // console.log(self.addList);
        // console.log(this.AnswerValueArr);
      }
    },
    _preview () {
      this.$router.push({
        path: '/issueQuestionnaire',
        query: { id: this.$route.query.id, type: this.type }
      })
    },
    _setChildren (id, index) {
      //修改子问卷
      this.$router.push({
        path: '/addQuestionnaire',
        query: {
          type: this.$route.query.type,
          child: 4,
          childIndex: index,
          id: this.$route.query.id,
          childrenId: id
        }
      })
    },
    _removeChild (i) {
      this.childrenList.splice(i, 1)
      this.$store.commit('TOGGLE_CHILDREN_QUESTIONNAIRE', {
        clear: 1,
        list: this.childrenList
      })
    },
    _addChild () {
      this.$router.push({
        path: '/addQuestionnaire',
        query: {
          type: this.$route.query.type,
          child: 1,
          id: this.$route.query.id
        }
      })
    },
    _delvisibleScope (i) {
      this.visibleScopeList.splice(i, 1)
    },
    _delRelevanceObject (i) {
      this.relevanceObject.splice(i, 1)
    },
    _visibleScope (value) {
      this.visibleScopeList = value
    },
    _relevanceObject (value) {
      this.relevanceObject = value
    },
    _returnPage () {
      window.history.back(-1)
    },
    _setData (i) {
      let self = this,
        data
      if (this.isScreen) {
        i = this._getIndexForId(this.screenData[i].OptionTitle, this.infoData)
        data = this.infoData[i]
      } else {
        if (this.skipId) {
          data = this.questionnaireList[i]
        } else {
          data = this.infoData[i]
        }
      }

      this.CloneItem = this.ObjectClone(data)
      this.addList = data.Children
      this.titleType = data.Type
      this.OptionTitle = data.OptionTitle
      this.AnswerValue = data.AnswerValue
      this.AnswerValueArr = data.AnswerValueArr
      this.AnswerValueInput =
        data.Type == 1 || data.Type == 3
          ? data.AnswerValue
          : Array.isArray(data.AnswerValueArr)
          ? data.AnswerValueArr
          : JSON.parse(data.AnswerValueArr)
      this.AnalysisValue = data.AnalysisValue
      this.Score = data.Score
      this.isMust = data.ConfigParam.isMust == 1 ? true : false
      this.UN =
        data.ConfigParam.Wnumber.WB == 0 ? '' : data.ConfigParam.Wnumber.WB
      for (let key in data.ConfigParam.Content) {
        if (data.ConfigParam.Content[key] == 1) {
          self.Content = key
        }
      }
      this.setIndex = i
      this.visible = true
      this.Tag = data.Tag
      this.opExplain = data.Explain
    },
    _createdDate (value, dateString) {
      this.startDate = dateString[0]
      this.endDate = dateString[1]
      // let date1 = new Date(this.startDate);
      // this.startDate = date1.getFullYear()+'-'+(date1.getMonth()+1<10?'0'+(date1.getMonth()+1):date1.getMonth()+1)+'-'+(date1.getDate()<10?'0'+date1.getDate():date1.getDate());
      // let date2 = new Date(this.endDate);
      // this.endDate = date2.getFullYear()+'-'+(date2.getMonth()+1<10?'0'+(date2.getMonth()+1):date2.getMonth()+1)+'-'+(date2.getDate()<10?'0'+date2.getDate():date2.getDate());
    },
    _onTimeOk (value) {
      this.startDate = this.__moment__(value[0]._d).format(
        'YYYY-MM-DD HH:mm:ss'
      )
      this.endDate = this.__moment__(value[1]._d).format('YYYY-MM-DD HH:mm:ss')
    },
    _subMit () {
      let Score = 0,
        self = this
      if (this.titleVal == '') {
        this.$message.error('请填写问卷名称')
        return false
      }
      for (let i = 0; i < self.infoData.length; i++) {
        Score += Number(self.infoData[i].Score)
      }
      this.totalScore = Score
      if (Number(Score) < Number(this.PassScore)) {
        this.$message.error('及格分不能大于总分')
        return false
      }

      let data = {
        Title: this.titleVal,
        CategoryId:
          this.questionnaireType == -1
            ? ''
            : this.classifyList[this.questionnaireType].Id,
        Type: this.$route.query.type,
        StartTime: this.startDate,
        EndTime: this.endDate,
        Duration: this.Duration,
        IsLimitDuration: this.IsLimitDuration,
        AnswerCount: this.AnswerCount,
        IsAnswerLimit: this.IsAnswerLimit,
        Score: this.totalScore ? this.totalScore : 0,
        PassScore: this.PassScore,
        Explain: this.questionnaireDel,
        EndExplain: this.EndExplain,
        IsShare: this.isShare ? 1 : 0,
        isQB: this.isQB ? 1 : 0,
        Enable: 0,
        CreateUserId: user.uid,
        CreateUserName: user.name,
        OptionList: JSON.stringify(this.infoData),
        Label: this.identification,
        ChildrenList: JSON.stringify(this.childrenList), //JSON.stringify(this.childrenList)
        Relevance: JSON.stringify(this.relevanceObject),
        PermitUser: JSON.stringify(this.visibleScopeList),
        IsLimitPlatform:this.IsLimitPlatform
      }
      //如果是子问卷的话直接保存数据然后直接跳转
      if (this.child == 1) {
        this.$store.commit('TOGGLE_CHILDREN_QUESTIONNAIRE', {
          clear: 2,
          list: data
        })
        this.$router.push({
          path: '/addQuestionnaire',
          query: {
            type: this.$route.query.type,
            id: this.$route.query.id,
            child: 3
          }
        })
      } else if (this.child == 4) {
        let list = this.$store.state.app.childrenQuestionnaire
        list[this.$route.query.childIndex] = data
        this.$store.commit('TOGGLE_CHILDREN_QUESTIONNAIRE', {
          clear: 1,
          list: list
        })
        this.$router.push({
          path: '/addQuestionnaire',
          query: {
            type: this.$route.query.type,
            id: this.$route.query.id,
            child: 3
          }
        })
      } else {
        if (this.$route.query.id && !this.$route.query.isRed) {
          data.Id = this.$route.query.id
          data.UserId = user.uid
          data.UserName = user.name
          this.$axios.post(5836104, data, res => {
            if (res.data.code == 1) {
              this.$message.success(res.data.msg)
              this.$store.commit('TOGGLE_CHILDREN_QUESTIONNAIRE', {
                clear: 1,
                list: []
              })
              setTimeout(function () {
                self.$router.push({ path: '/questionnaire' })
              }, 2000)
            } else {
              this.$message.error(res.data.msg)
            }
          })
        } else {
          data.Enable = 1
          this.$axios.post(5836100, data, res => {
            if (res.data.code == 1) {
              this.$message.success(res.data.msg)
              this.$store.commit('TOGGLE_CHILDREN_QUESTIONNAIRE', {
                clear: 1,
                list: []
              })
              this.$router.push({
                path: '/issueQuestionnaire',
                query: { id: res.data.id, type: this.type }
              })
            } else {
              this.$message.error(res.data.msg)
            }
          })
        }
      }
    },
    _addInfoData () {
      let Score = 0,
        self = this
      if (this.OptionTitle == '') {
        this.$message.error('题目必填哦')
        return false
      } else if (
        this.titleType != 4 &&
        this.titleType != 3 &&
        this.addList.length == 0
      ) {
        this.$message.error('选项必填哦')
        return false
      } else if (this.Score == 0 && this.type == 2) {
        this.$message.error('分数必填哦')
        return false
      } else if (
        this.type == 2 &&
        this.titleType != 4 &&
        this.AnswerValue == ''
      ) {
        this.$message.error('答案必选哦')
        return false
      }
      for (let i = 0; i < this.addList.length; i++) {
        if (self.addList[i].OptionTitle == '') {
          self.addList.splice(i, 1)
        }
      }
      let data = {
        Type: this.titleType,
        OptionTitle: this.OptionTitle,
        OptionValue: '', //这个参数没用
        AnswerValue: this.AnswerValue,
        AnswerValueArr: JSON.stringify(this.AnswerValueArr),
        AnalysisValue: this.AnalysisValue,
        Score: this.Score,
        Tag: this.Tag,
        Explain: this.opExplain,
        ConfigParam: {
          isMust: this.isMust ? 1 : 0,
          Content: {
            UN: this.Content && this.Content != 'UN' ? 1 : 0,
            NB: this.Content == 'NB' ? 1 : 0,
            LR: this.Content == 'LR' ? 1 : 0,
            CN: this.Content == 'CN' ? 1 : 0,
            EM: this.Content == 'EM' ? 1 : 0,
            MB: this.Content == 'MB' ? 1 : 0
          },
          Wnumber: {
            UN: this.UN ? 1 : 0,
            WB: this.UN
          }
        },
        Children: this.addList
      }
      if (this.setIndex == -1) {
        this.infoData.push(data)
        this.screenData.push(data)
      } else {
        this.infoData[this.setIndex].Type = this.titleType
        this.infoData[this.setIndex].OptionTitle = this.OptionTitle
        this.infoData[this.setIndex].OptionValue = '' //这个参数没用
        this.infoData[this.setIndex].AnswerValue = this.AnswerValue
        this.infoData[this.setIndex].AnalysisValue = this.AnalysisValue
        this.infoData[this.setIndex].AnswerValueArr = this.AnswerValueArr
        this.infoData[this.setIndex].Score = this.Score
        this.infoData[this.setIndex].Tag = this.Tag
        this.infoData[this.setIndex].Explain = this.opExplain
        if (this.titleType == 4) {
          this.infoData[this.setIndex].ConfigParam = {
            Content: {
              UN: this.Content && this.Content != 'UN' ? 1 : 0,
              NB: this.Content == 'NB' ? 1 : 0,
              LR: this.Content == 'LR' ? 1 : 0,
              CN: this.Content == 'CN' ? 1 : 0,
              EM: this.Content == 'EM' ? 1 : 0,
              MB: this.Content == 'MB' ? 1 : 0
            },
            Wnumber: {
              UN: this.UN ? 1 : 0,
              WB: this.UN
            }
          }
        }
        this.infoData[this.setIndex].ConfigParam.isMust = this.isMust ? 1 : 0
        this.infoData[this.setIndex].Children = this.addList
        this.screenData[
          this._getIndexForId(
            this.infoData[this.setIndex].OptionTitle,
            this.screenData
          )
        ] = this.infoData[this.setIndex]
      }
      this._clear()
      for (let i = 0; i < this.infoData.length; i++) {
        Score += parseInt(self.infoData[i].Score)
      }
      this.totalScore = Score
      this.setIndex = -1
      this.visible = false
      this.scoreList = this.setScoreList(this.infoData)
    },
    _ChildOptionInput (checked) {
      this.addList[this.addActive].ConfigParam.isMust = checked == true ? 1 : 0
    },
    _OptionInput (checked) {
      this.addList[this.addActive].OptionInput = checked == true ? 1 : 0
    },
    _OptionContent (value) {
      let Content = { UN: 0, NB: 0, LR: 0, CN: 0, EM: 0, MB: 0 }
      Content[value] = 1
      if (value != 'UN') {
        Content.UN = 1
      }
      if (this.titleType == 4 && this.addList.length == 0) {
        //解决判断题没子项的时候报错
        this.addList.push({ ConfigParam: { Content: Content } })
      } else {
        this.addList[this.addActive].ConfigParam.Content = Content
      }
    },
    _OptionUn () {
      if (this.titleType == 4) {
        //解决判断题没子项的时候报错
        this.addList[0].ConfigParam.Wnumber = {}
      } else {
        this.addList[this.addActive].ConfigParam.Wnumber.WB = this.UN
        this.addList[this.addActive].Value = this.Value
      }
    },
    _clear () {
      //清空参数
      this.OptionTitle = ''
      this.AnswerValue = ''
      this.AnalysisValue = ''
      this.Score = ''
      this.isMust = false
      this.Content = 'UN'
      this.UN = ''
      this.AnswerValue = ''
      this.AnswerValueInput = []
      this.addList = []
      this.addActive = -1
      this.setInputBtn = -1
      this.setIndex = -1
      this.AnswerValueArr = []
      // this.Value = 0;
      this.Tag = ''
      this.opExplain = ''
    },
    _setTitleDelBlur () {
      // if(this.titleDel==''){
      //     this.titleDel = '请输入问卷/考卷的备注'
      // }
      document.getElementsByClassName(
        'questionnaire-des'
      )[0].childNodes[0].style.background = '#fff'
    },
    _setTitleDel (e) {
      this.isReadonly2 = false
      e.currentTarget.select()
      document.getElementsByClassName(
        'questionnaire-des'
      )[0].childNodes[0].style.background = '#eee'
    },
    _setTitleBlur () {
      // if(this.titleVal==''){
      //     this.titleVal = '双击输入问卷名称'
      // }
      document.getElementsByClassName(
        'questionnaire-title'
      )[0].childNodes[0].style.background = '#fff'
    },
    _setTitle (e) {
      this.isReadonly = false
      document.getElementsByClassName(
        'questionnaire-title'
      )[0].childNodes[0].style.background = '#eee'
      e.currentTarget.select()
    },
    _getType (value) {
      console.log(value)
      this.questionnaireType = value
    },
    _saveBatch () {
      let list = this.batchAdd.split(/[(\r\n)\r\n]+/)

      let arr = []
      for (let i = 0; i < list.length; i++) {
        if (list[i] != '') {
          arr.push({
            OptionTitle: list[i],
            OptionValue: '',
            IsAnswer: 0,
            OptionInput: 0,
            ConfigParam: {
              isMust: 0,
              Content: { UN: 0, NB: 0, LR: 0, CN: 0, EM: 0, MB: 0 },
              Wnumber: { UN: 0, WB: '' }
            }
          })
        }
      }
      this.addList = this.addList.concat(arr)
      this.batchAdd = ''
      this.batchAlert = false
      this.addActive = -1
      this.setInputBtn = -1
    },
    _hideBatchAlert () {
      this.batchAlert = false
    },
    _cutTitleType (e) {
      this.titleType = e.target.value
    },
    _setAddItem (i) {
      console.log(this.AnswerValue)
      this.addActive = i
      this.setInputBtn = i
      this.OptionInput =
        this.addList[this.addActive].OptionInput == 1 ? true : false
      let list = this.addList[this.addActive].ConfigParam.Content
      let self = this
      for (let key in list) {
        if (list[key] == 1) {
          self.Content = key
        }
      }
      this.ChildOptionInput =
        this.addList[this.addActive].ConfigParam.isMust == 1 ? true : false
      this.UN = this.addList[this.addActive].ConfigParam.Wnumber.WB
      this.Value = this.addList[this.addActive].Value
        ? this.addList[this.addActive].Value
        : 0
      //如果选项值为空的话直接删除掉
      for (let key = 0; key < this.addList.length; key++) {
        if (self.addList[key].OptionTitle == '') {
          self.addList.splice(key, 1)
        }
      }
    },
    _removeAddList (i) {
      this.addList.splice(i, 1)
      let str = ''
      for (var i = 0; i < this.addList.length; i++) {
        if (this.addList[i].IsAnswer == 1) {
          if (str != '') {
            str += ','
          }
          str += this.addList[i].OptionTitle
        }
      }
      this.AnswerValueArr = str ? str.split(',') : []
      this.AnswerValue = str
      //删除重新赋值答案
      this.AnswerValueInput = this.AnswerValueArr
    },
    _addActive (i) {
      let self = this
      this.setInputBtn = i
      this.addActive = -1
      this.Content = 'UN'
      this.UN = 0
    },
    _showAddItem () {
      let item = {
        OptionTitle: '',
        OptionValue: '',
        OptionInput: 0,
        IsAnswer: 0,
        ConfigParam: {
          isMust: 0,
          Content: { UN: 0, NB: 0, LR: 0, CN: 0, EM: 0, MB: 0 },
          Wnumber: { UN: 0, WB: '' }
        }
      }
      if (
        this.addList.length - 1 >= 0 &&
        this.addList[this.addList.length - 1].OptionTitle == ''
      ) {
        this.setInputBtn = this.addList.length - 1
      } else {
        this.addList.push(item)
        this.setInputBtn = this.addList.length - 1
        this.addActive = this.addList.length - 1
      }
    },
    _showVisible (i) {
      this.titleType = i
      this.visible = true
      this.Tag = ''
      this.opExplain = ''
      if (i === 3) {
        this.addList = [
          {
            OptionTitle: '是',
            OptionValue: '',
            OptionInput: 0,
            ConfigParam: {
              isMust: 0,
              Content: { UN: 0, NB: 0, LR: 0, CN: 0, EM: 0, MB: 0 },
              Wnumber: { UN: 0, WB: '' }
            }
          },
          {
            OptionTitle: '否',
            OptionValue: '',
            OptionInput: 0,
            ConfigParam: {
              isMust: 0,
              Content: { UN: 0, NB: 0, LR: 0, CN: 0, EM: 0, MB: 0 },
              Wnumber: { UN: 0, WB: '' }
            }
          }
        ]
        this.AnswerValueInput = '是'
        this.setInputBtn = -1
      }
    },
    _hideCancel () {
      this.visible = false
      if (this.skipId) {
        this.questionnaireList[this.setIndex] = this.CloneItem
      } else {
        this.infoData[this.setIndex] = this.CloneItem
      }
      this._clear()
    },
    _upItem (i) {
      //筛选情景下不能排序
      if (this.isScreen) {
        return false
      }
      let upItem = this.infoData[i]
      let downItem = this.infoData[i - 1]
      this.infoData[i] = downItem
      this.infoData[i - 1] = upItem
      this.infoData.sort()
    },
    _downItem (i) {
      //筛选情景下不能排序
      if (this.isScreen) {
        return false
      }
      let downItem = this.infoData[i]
      let upItem = this.infoData[i + 1]
      this.infoData[i] = upItem
      this.infoData[i + 1] = downItem
      this.infoData.sort()
    },
    _removeItem (i) {
      if (this.isScreen) {
        let j = this._getIndexForId(
          this.screenData[i].OptionTitle,
          this.infoData
        )
        this.infoData.splice(j, 1)
        this.screenData.splice(i, 1)
      } else {
        this.infoData.splice(i, 1)
      }
    },
    handleChange (value) {
      this.SelectScore = value
    },
    _skipPage (i) {
      if (i == 1) {
        this.$router.push({
          path: '/issueQuestionnaire',
          query: { type: this.type, id: this.skipId }
        })
      } else if (i == 2) {
        this.$router.push({
          path: '/statisticsQuestionnaire',
          query: { type: this.type, id: this.skipId }
        })
      }
    },
    _deriveData () {
      const hide = this.$message.loading('文件下载中...', 0)
      //数据导出
      this.$axios.post(
        5836149,
        { Qid: this.$route.query.id, UserId: user.uid },
        res => {
          if (res.data.code == 1) {
            window.open(res.data.url)
          } else {
            this.$message.error(res.data.msg)
            // this.$message.destroy();
          }
          setTimeout(hide, 1500)
        }
      )
    },
    _inputChange (type, e) {
      let self = this
      let str = ''
      for (let j = 0; j < self.addList.length; j++) {
        if (self.addList[j].IsAnswer == 1) {
          if (str != '') {
            str += ','
          }
          str += self.addList[j].OptionTitle
        }
      }

      let arr = str ? str.split(',') : []
      this.AnswerValue = str
      this.AnswerValueArr = arr
      this.AnswerValueInput = type == 1 ? str : arr
    },
    _reset () {
      this.isScreen = false
      this.SelectScore = ''
      this.screenData = this.infoData
    },
    _searchData () {
      let score = this.SelectScore
      if (!score) {
        return false
      }
      this.isScreen = true
      let data = this.infoData
      let length = data.length
      let new_data = []
      for (var i = 0; i < length; i++) {
        if (data[i].Score == score) {
          new_data.push(data[i])
        }
      }
      this.screenData = new_data
    },
    setScoreList (array) {
      let lst = []
      let _tmp = []
      let n = 0
      let length = array.length
      for (let i = 0; i < length; i++) {
        let score = array[i].Score
        let k = score
        if (_tmp[k] == undefined) {
          lst[n] = score
          _tmp[k] = score
          n++
        }
      }

      let sortLst = this._sort(lst)
      let ret = []
      for (var i = 0; i < sortLst.length; i++) {
        let tmp = []
        tmp['id'] = i
        tmp['title'] = sortLst[i]
        tmp['value'] = sortLst[i]
        ret[i] = tmp
      }
      return ret
    },
    _sort (arr) {
      //排序
      for (var j = 0; j < arr.length - 1; j++) {
        for (var i = 0; i < arr.length - 1 - j; i++) {
          if (arr[i] > arr[i + 1]) {
            var temp = arr[i]
            arr[i] = arr[i + 1]
            arr[i + 1] = temp
          }
        }
      }
      return arr
    },
    _getIndexForId (OptionTitle, array) {
      let index = 0
      for (let j = 0; j < array.length; j++) {
        if (OptionTitle == this.infoData[j].OptionTitle) {
          index = j
          break
        }
      }
      return index
    },
  }
}
</script>

<style lang="less">
.AddQuestionnaire {
  height: 100%;
  .crumbs {
    position: relative;
    height: 64px;
    padding-right: 30px;
    line-height: 64px;
    border-radius: 4px;
    background: #fff;
    text-align: center;
    color: #666;
    font-size: 14px;
    .return {
      position: absolute;
      top: 50%;
      left: 25px;
      transform: translateY(-50%);
      font-size: 18px;
      color: #9d9d9d;
      cursor: pointer;
    }
    .crumbs-icon {
      margin-left: 30px;
      margin-right: 30px;
      font-size: 14px;
      color: #9d9d9d;
    }
    .crumbs-item {
      position: relative;
      cursor: pointer;
      span {
        position: absolute;
        bottom: -24px;
        left: 0;
        width: 100%;
        height: 2px;
      }
    }
  }
  .container {
    display: flex;
    min-height: 100%;
    margin-top: 15px;
    .content-left {
      width: 315px;
      min-height: 100%;
      padding: 30px 15px;
      background: #fff;
      border-radius: 4px;
      .item {
        margin-bottom: 15px;
        .item-label {
          margin-right: 15px;
          color: #666;
          font-size: 14px;
        }
        .object-list {
          justify-content: end;
          flex-wrap: wrap;
          li {
            position: relative;
            width: 50px;
            margin-right: 5px;
            margin-bottom: 5px;
            text-align: center;
            img {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              margin-bottom: 8px;
            }
            .object-name {
              font-size: 12px;
            }
            .remove-icon {
              position: absolute;
              top: -5px;
              right: -2px;
              font-size: 14px;
              color: #9e9e9e;
              cursor: pointer;
            }
          }
        }
      }
      .line {
        width: 100%;
        height: 1px;
        margin: 20px 0;
        background: #f0f0f0;
      }
    }
    .content-right {
      flex: 1;
      margin-left: 15px;
      .container-header {
        padding: 25px 15px;
        background: #fff;
        border-radius: 4px;
        text-align: center;
        .questionnaire-title {
          input {
            width: 100%;
            margin-bottom: 15px;
            border: none;
            font-size: 18px;
            color: #666;
            text-align: center;
            outline: none;
          }
          input::-webkit-input-placeholder {
            color: #bbb;
          }
        }
        .questionnaire-des {
          input {
            width: 100%;
            color: #666;
            font-size: 16px;
            border: none;
            text-align: center;
            outline: none;
          }
          input::-webkit-input-placeholder {
            color: #bbb;
          }
        }
      }
      .item-header-cate {
        padding: 15px 20px;
        background: #fff;
        border-radius: 4px;
        margin-top: 15px;
        .screen-list-item {
          margin-right: 20px;
          flex: 1;
        }
        .screen-item-label {
          margin-right: 10px;
          color: #666;
          font-size: 14px;
          white-space: nowrap;
        }
      }
      .content-item {
        margin-top: 15px;
        padding: 25px 15px;
        background: #fff;
        border-radius: 4px;
        .item-title {
          margin-right: 20px;
          margin-bottom: 20px;
          color: #333;
          font-size: 18px;
        }
        .Explain{
          font-size: 10px;
        }
      }
      .operation-btn {
        white-space: nowrap;
        i {
          margin-right: 15px;
          font-size: 20px;
          color: #9d9d9d;
          cursor: pointer;
        }
      }
      .item-content {
        .item-row {
          &:last-child {
            margin-bottom: 0;
          }
          margin-bottom: 20px;
        }
      }
      .content-footer {
        margin-top: 15px;
        padding: 25px 15px;
        background: #fff;
        border-radius: 4px;
        text-align: center;
        .footer-title {
          font-size: 16px;
          color: #666;
        }
        .footer-btn {
          margin-top: 15px;
          button {
            margin-right: 15px;
          }
        }
      }
    }
  }
}
.flex1{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
}
.alert-container {
  position: relative;
  min-height: 100%;
  .alert-left {
    position: relative;
    flex: 1;
    padding-right: 35px;
    margin-right: 15px;
    .title-input {
      width: 400px;
      min-height: 50px;
      resize: none;
      font-size: 18px;
      color: #333;
      border: none;
      outline: none;
    }
    .grade {
      font-size: 12px;
      color: #666;
      .grade-input {
        width: 40px;
        height: 24px;
        margin: 0 10px;
        border-radius: 4px;
        border: 1px solid #eaeaea;
        text-align: center;
      }
    }
    .add-btn {
      position: relative;
      margin-top: 10px;
      > div {
        cursor: pointer;
      }
      width: 400px;
      padding: 10px 20px;
      i {
        margin-right: 10px;
      }
      .batch-alert {
        position: absolute;
        bottom: -260px;
        left: 0;
        width: 100%;
        height: 240px;
        padding: 15px;
        border-radius: 4px;
        background: #fff;
        box-shadow: rgba(0, 0, 0, 0.06) 0px 4px 20px 1px,
          rgba(0, 0, 0, 0.08) 0px 1px 4px;
        z-index: 1000;
        .batch-alert-title {
          color: #333;
          font-size: 16px;
        }
        .batch-alert-des {
          margin-top: 8px;
          font-size: 14px;
          color: #666;
        }
      }
    }
    .paper {
      margin-top: 20px;
      .paper-row {
        margin-bottom: 15px;
        justify-content: end;
        .paper-row-label {
          margin-right: 15px;
          color: #333;
          font-size: 14px;
        }
      }
    }
    .search-btn {
      font-size: 18px;
      color: #b9b9b9;
      cursor: pointer;
      margin-right: 10px;
    }
    .preview {
      .item-row {
        input {
          cursor: pointer;
        }
        margin-bottom: 15px;
      }
    }
  }
  .line {
    position: absolute;
    top: -24px;
    right: 0;
    width: 15px;
    min-height: 800px;
    background: #f8f9fa;
  }
  .alert-right {
    width: 220px;
    padding: 0 10px;
    .right-title {
      display: inline-block;
      padding: 4px 10px;
      background: #666;
      color: #fff;
      border-radius: 2px;
      font-size: 14px;
    }
    .right-item-row {
      padding: 15px 0;
      font-size: 14px;
      color: #666;
      border-bottom: 1px solid #f4f4f4;
    }
  }
}
.alert-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  button {
    width: 120px;
  }
}
.person {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
/*.ant-drawer-body{*/
/*padding: 0 !important;*/
/*}*/
</style>
